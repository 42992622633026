import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const PreloaderTable = () => (
  <TableContainer>
    <Paper className="my-3 border">
      <Table>
        <TableHead>
          <TableRow>
            {[1, 2, 3, 4, 5].map((item) => (
              <TableCell key={item}>
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[1, 2, 3, 4, 5]?.map((item) => (
            <TableRow key={item}>
              {[1, 2, 3, 4, 5].map((item) => (
                <TableCell key={item}>
                  <Skeleton variant="text" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  </TableContainer>
);

export default PreloaderTable;
