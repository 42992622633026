import useInternetConnection from 'hooks/useInternetConnection';
import Cookies from 'js-cookie';
import React from 'react';
import httpService from 'service/httpService';
import { Subscription } from 'service/subscription';
import userService, { User } from 'service/userService';

const defOrgId = userService.getOrgID();
const OrganizationContext = React.createContext({});

const OrganizationContextProvider = ({ children }) => {
  const subscriptionService = new Subscription();
  const { isOnline } = useInternetConnection();

  const accessToken = Cookies.get('_access_auth');

  const [organizationId, setOrganizationId] = React.useState(defOrgId);
  const [taxes, setTaxes] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [organization, setOrganization] = React.useState(null);
  const [admin, setAdmin] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [users, setUsers] = React.useState({});
  const [plan, setPlan] = React.useState(null);
  const [receipts, setReceipts] = React.useState([]);
  const [currencies, setCurrencies] = React.useState([]);
  const [invitations, setInvitations] = React.useState({});
  const [branchs, setBranchs] = React.useState([]);
  const [departments, setDepartments] = React.useState([]);
  const [refetch, setRefetch] = React.useState(false);

  React.useEffect(() => {
    setOrganizationId(userService.getOrgID());
  }, [refetch]);

  const currentPath = window.location.pathname.split('/')[1];

  React.useEffect(() => {
    if (!isOnline) return;
    if (!accessToken) return;
    if (!organizationId) return;
    if (currentPath !== 'dashboard') return;
    getPlan();
    getAdministrator();
    getOrganization();
  }, [refetch, accessToken, organizationId, isOnline]);

  async function getOrganization() {
    try {
      const { data } = await userService.getCurrentOrg();
      localStorage.setItem('ORGANIZATION', JSON.stringify(data?.data));
      setOrganization(data?.data);
      setIsLoading(false);
    } catch (ex) {
      httpService.showFeedback(ex);
      setIsLoading(false);
    }
  }

  async function getAdministrator() {
    try {
      const { data } = await userService.getAuthAdministrator();
      setAdmin(data?.data);
    } catch (ex) {
      httpService.showFeedback(ex);
    }
  }

  async function getPlan() {
    try {
      const { data } = await subscriptionService.getCurrentSub();
      setPlan(data?.data);
    } catch (ex) {
      httpService.showFeedback(ex);
    }
  }

  return (
    <OrganizationContext.Provider
      value={{
        admin,
        taxes,
        organization,
        plan,
        users,
        setUsers,
        setRefetch,
        isFetching: isLoading,
        user,
        setUser,
        currencies,
        setCurrencies,
        invitations,
        setInvitations,
        branchs,
        setBranchs,
        departments,
        setDepartments,
        receipts,
        organizationId,
        setReceipts,
      }}>
      {children}
    </OrganizationContext.Provider>
  );
};

export { OrganizationContext, OrganizationContextProvider };
