import React from 'react';
import { AppContextProvider } from './app';
import { UserContextProvider } from './user/user';
import { OrganizationContextProvider } from './organization/organization';
import { StatContextProvider } from './organization/accounting/stats';
import { InvoiceContextProvider } from './organization/accounting/invoice';
import { AccountingContextProvider } from './organization/accounting';
import { TransactionContextProvider } from './organization/accounting/transaction';
import { FixedAssetsContextProvider } from './organization/accounting/fixedAssets';
import { ProdAndSerContextProvider } from './organization/accounting/productAndServicet';
import { HRContextProvider } from './organization/hr';
import { ReferrerContextProvider } from './user/referrer';
import { RequestContextProvider } from './request-context';

const AppContextLayout = ({ children }) => {
  return (
    <React.Fragment>
      <RequestContextProvider>
        <UserContextProvider>
          <ReferrerContextProvider>
            <AppContextProvider>
              <OrganizationContextProvider>
                <StatContextProvider>
                  <InvoiceContextProvider>
                    <AccountingContextProvider>
                      <TransactionContextProvider>
                        <FixedAssetsContextProvider>
                          <ProdAndSerContextProvider>
                            <HRContextProvider>{children}</HRContextProvider>
                          </ProdAndSerContextProvider>
                        </FixedAssetsContextProvider>
                      </TransactionContextProvider>
                    </AccountingContextProvider>
                  </InvoiceContextProvider>
                </StatContextProvider>
              </OrganizationContextProvider>
            </AppContextProvider>
          </ReferrerContextProvider>
        </UserContextProvider>
      </RequestContextProvider>
    </React.Fragment>
  );
};

export default AppContextLayout;
