import Config from 'config/Config';
import { PATHNAMES } from 'lib/constants/pathnames';
import React from 'react';

let idleTime = eval(Config.IDLE_DURATION);
const useWindowOnIdle = () => {
  React.useEffect(() => {
    let timeout;

    function clearCookies() {
      location.replace(PATHNAMES.SIGN_OUT);
    }

    function resetTimer() {
      clearTimeout(timeout);
      timeout = setTimeout(clearCookies, idleTime);
    }

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);

    timeout = setTimeout(clearCookies, idleTime);

    return () => {
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      clearTimeout(timeout);
    };
  }, []);

  return {};
};

export default useWindowOnIdle;
